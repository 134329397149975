import React, { useState } from 'react';
import { TextField, Button, Grid, Alert } from '@mui/material';
import Box from '@mui/material/Box';

import httpProvider from '../../provider/HttpProvider';

const AddTagForm = ({ onTagAdded, onTagError }) => {
    const [tagName, setTagName] = useState('');
    const [error, setError] = useState('');
    const [token] = useState(localStorage.getItem("token"))

    const handleSubmit = (event) => {
        event.preventDefault();

        if (tagName.trim() === '') {
            const msg = 'Tag name cannot be empty'
            setError(msg)
            onTagError({ message: msg, severity: 'error' });
            return;
        }

        const data = {
            name: tagName
        };

        httpProvider.post('/api/tags/add', data, {
            headers: {
                'Authorization': token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                onTagError({ message: 'Tag added successfully', severity: 'success' });;
                setTagName('');
                if (onTagAdded) {
                    onTagAdded(response);
                }
            })
            .catch((error) => {
                const msg = 'Failed to add tag'
                setError(msg)
                onTagError({ message: msg, severity: 'error' });
            });
    };

    return (
        <Box component="form" onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <TextField
                        label="Tag Name"
                        variant="outlined"
                        fullWidth
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                        error={!!error}
                        helperText={error}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 1, mb: 2 }}>
                        Add
                    </Button>
                </Grid>
            </Grid>
        </Box >
    );
};

export default AddTagForm;
